import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GeneralBanner from '../components/generalBanner'
import Coordination from '../components/reports/coordination'
import Certification from '../components/certification'
import Band from "../components/band"

const Reports = () => (
  <Layout>
    <SEO title="Reports" />
    <GeneralBanner
      title="REPORTS"
    />
    <Coordination />
    <Certification
      background="#f2f2f2"
    />
    <Band
      bandBackground="#23227B"
    />
  </Layout>
)

export default Reports
