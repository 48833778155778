import React from 'react'
import { Div, P } from '../styled-components'

const Coordination = () => (
  <Div
    padding="50px 20px 30px"
    display="flex"
    flexDirection="column"
    align_items="center"
  >
    <P
      margin="0 0 20px"
      fontSize="32px"
      fontWeight="bold"
      color="#666"
      textAlign="center"
    >
      Coordination Drawings 100% Digital
    </P>
    <P
      maxWidth="561px"
      margin="0 0 30px"
      fontSize="18px"
      color="#666"
      textAlign="center"
    >
      This allows for us to complete design review, have blank reports complete, and have advanced knowledge of all projects before ever setting foot on site.
    </P>
    <Div
      width="280px"
      height="2px"
      background_color="#69B02A"
    />
    <P
      margin="30px 0 20px"
      fontSize="32px"
      fontWeight="bold"
      color="#666"
      textAlign="center"
    >
      100% Digital Reports
    </P>
    <P
      maxWidth="632px"
      margin="0 0 30px"
      fontSize="18px"
      color="#666"
      textAlign="center"
    >
      This allows for the supervisors to enter the information on reports first hand. Also with this software you can gain access to the project and review completed items, notes, and deficiencies without asking for reports.
    </P>
    <Div
      width="280px"
      height="2px"
      background_color="#69B02A"
    />
    <P
      maxWidth="454px"
      margin="30px 0 20px"
      fontSize="32px"
      fontWeight="bold"
      color="#666"
      textAlign="center"
    >
      Certified Test and Balance Reports within 24 hours of completion
    </P>
    <P
      maxWidth="676px"
      margin="0 0 50px"
      fontSize="18px"
      color="#666"
      textAlign="center"
    >
      We feel that this allows for the entire construction team to have reviewed the report within days of our completion and any questions or concerns that may arise can be addressed and/or corrected quickly.  With these three steps we hope that we can be a Test and Balance Contractor that you can rely on for any project no matter how big or small.
    </P>
    {/* <Div
      width="500px"
      height="300px"
      width960="280px"
      height960="220px"
      background_color="#666"
      display="flex"
      align_items="center"
      justify_content="center"
    >
      <P
        margin="0"
        fontSize="22px"
        fontWeight="bold"
        color="#fff"
      >
        FOTO AQUÍ
      </P>
    </Div> */}
  </Div>
)

export default Coordination